import {
  AppliedCompanyText,
  AppliedJobListContainer,
  AppliedJobListWrapper,
  AppliedJobText,
  AppliedJobTextContainer,
} from "./styles";

export default function AppliedJobList(props) {
  const { dialog, bulkAppliedJobs, secondaryBulk } = props;

  return (
    <AppliedJobListWrapper $secondaryBulk={secondaryBulk}>
      <AppliedJobListContainer>
        {bulkAppliedJobs.map((job, index) => {
          return (
            <AppliedJobTextContainer key={index}>
              <AppliedJobText>{job?.job?.title}, </AppliedJobText>
              <AppliedCompanyText>{job?.job?.company?.name}</AppliedCompanyText>
            </AppliedJobTextContainer>
          );
        })}
      </AppliedJobListContainer>
    </AppliedJobListWrapper>
  );
}
