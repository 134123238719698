import React from "react";
import { useSelector } from "react-redux";
import AppliedJobList from "../AppliedJobList/AppliedJobList";
import BulkApplyList from "../BulkApplyList/BulkApplyList";
import { CloseButton, HeaderStyled } from "../styles";
import {
  ArrowDownIcon,
  CloseLink,
  CollapsibleContainer,
  SecondaryHeaderContainer,
  SecondaryWrapper,
} from "./styles";

export default function SecondaryBulkApply(props) {
  const {
    dialog,
    selectedJobs,
    showList,
    setShowList,
    setSelectedJobs,
    recommendedJobs,
  } = props;

  const bulkAppliedJobs = useSelector((state) => state?.jobs?.bulkAppliedJobs);

  function handleClose() {
    setShowList(false);
  }

  function handleToggleList() {
    setShowList(!showList);
  }

  return (
    <SecondaryWrapper dialog={dialog}>
      <SecondaryHeaderContainer open={showList} dialog={dialog}>
        <HeaderStyled size={"secondary"}>
          Here are the job(s) you've applied to:
        </HeaderStyled>
        <CloseButton onClick={handleToggleList}>
          <ArrowDownIcon open={showList} />
        </CloseButton>
      </SecondaryHeaderContainer>
      <CollapsibleContainer dialog={dialog} in={showList}>
        <AppliedJobList
          bulkAppliedJobs={bulkAppliedJobs}
          secondaryBulk={true}
        />
        <CloseLink onClick={handleClose}>Close List</CloseLink>
      </CollapsibleContainer>
      {dialog ? (
        <BulkApplyList
          dialog={dialog}
          secondaryBulk={true}
          showList={showList}
          selectedJobs={selectedJobs}
          setSelectedJobs={setSelectedJobs}
          recommendedJobs={recommendedJobs}
        />
      ) : null}
    </SecondaryWrapper>
  );
}
