import CheckCircleIcon from "@heroicons/react/20/solid/CheckCircleIcon";
import ExclamationCircleIcon from "@heroicons/react/20/solid/ExclamationCircleIcon";
import XMarkIcon from "@heroicons/react/20/solid/XMarkIcon";
import Check from "@mui/icons-material/Check";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import { styled as styling } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Color from "../../../assets/colors";

export const DialogContainer = styling(Dialog)({
  "& .MuiDialog-container .MuiPaper-root": {
    border: "1.5px solid " + Color.black,
    borderRadius: "1.5rem",
    overflow: "clip",

    maxHeight: "680px",
    maxWidth: "1168px",

    transition: "all 0.3s ease-in-out",

    "@media (max-width: 1279px)": {
      maxWidth: "720px",
    },

    "@media (max-width: 640px)": {
      maxHeight: "610px",
      maxWidth: "331px",
    },
  },
});

export const BoxContainer = styling(Box)({
  display: "flex",
});

export const DialogHeader = styling("div")({
  display: "flex",
  flexDirection: "column",

  borderBottom: "1px solid" + Color.grey,

  padding: "1rem 1.5rem",

  "@media (max-width: 640px)": {
    padding: "0.75rem 0.75rem 0.5rem",
  },
});

export const DialogContent = styling("div")({
  height: "100%",

  "@media (max-width: 1279px)": {
    height: "100%",
  },

  "@media (max-width: 640px)": {
    height: "462px",
  },
});

export const DialogFooter = styling("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "1rem",

  backgroundColor: Color.white,
  borderTop: "1px solid" + Color.grey,

  padding: "1rem",
});

export const AppliedJobWrapper = styling("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  gap: "1.5rem",

  width: "100%",
  padding: "1rem",

  "@media (max-width: 640px)": {
    gap: "1rem",
    padding: "0.5rem 1rem",
    overflowY: "auto",
    scrollBehavior: "smooth",
    maxHeight: "462px",
  },
});

export const AppliedJobContainer = styling("div")({
  display: "flex",
  flexDirection: "column",
  gap: "0.75rem",

  "@media (max-width: 640px)": {
    gap: "0.375rem",
  },
});

export const TextStyled = styling(Typography)({});

export const ContentText = styling(Typography)({
  color: "rgba(0, 0, 0, 0.60)",
  fontSize: "0.875rem",
  fontStyle: "italic",
  lineHeight: "24px",
  letterSpacing: "0.15px",

  "@media (max-width: 640px)": {
    fontSize: "0.75rem",
    lineHeight: "20px",
  },
});

export const HeaderStyled = styling(Typography)((props) => ({
  color: "rgba(0, 0, 0, 0.87)",
  fontSize: props?.size === "secondary" ? "0.875rem" : "1.25rem",
  fontWeight: 600,
  lineHeight: "24px",

  "@media (max-width: 640px)": {
    fontSize: "0.875rem",
  },
}));

export const LinkStyled = styling("span")((props) => ({
  color: Color.hiredlyPurple,
  fontWeight: "700",
  textDecoration: props.textDecoration,

  "&:hover": {
    cursor: "pointer",
  },
}));

export const CloseButton = styling(ButtonBase)({});

export const ButtonStyled = styling(ButtonBase)((props) => ({
  color: Color.black,
  backgroundColor: Color.white,

  border: "1px solid" + Color.black,
  borderRadius: "6.25rem",

  fontFamily: "Inter",
  fontSize: "1rem",
  fontWeight: "500",
  letterSpacing: "0.074px",

  height: "42px",
  width: "200px",

  transition: "all 0.15s ease-in-out",

  "@media (max-width: 640px)": {
    fontSize: "0.75rem",
    letterSpacing: "0.055px",
    height: "30px",
    width: "100%",
  },

  ...(props.variant === "filled" && {
    color: Color.white,
    backgroundColor: props.black ? Color.black : Color.buttonGreen,
    border: "1.5px solid" + Color.black,

    fontWeight: "700",
  }),

  ...(props.disabled && {
    backgroundColor: Color.grey,
    border: "1px solid" + Color.grey,
    opacity: "0.5",
  }),
}));

export const CheckboxWrapper = styling("div")((props) => ({
  position: "relative",
}));

export const CheckboxStyled = styling(Checkbox)({
  padding: "0",
  "& .Mui-checked": {
    color: Color.black,
  },

  "&:hover": {
    background: "transparent",
  },
});

export const CheckboxHover = styling("div")((props) => ({
  position: "absolute",
  top: "0",
  left: "0",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  width: "100%",
  height: "100%",
}));

export const UncheckedBox = styling("div")((props) => ({
  backgroundColor: Color.white,
  border: "1px solid" + Color.black,
  borderRadius: props?.size === "small" ? "0.25rem" : "0.5rem",

  height: props?.size === "small" ? "18px" : "32px",
  width: props?.size === "small" ? "18px" : "32px",
}));

export const CheckedBox = styling("div")((props) => ({
  backgroundColor: Color.black,
  border: "1px solid" + Color.black,
  borderRadius: props?.size === "small" ? "0.25rem" : "0.5rem",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  "&:hover": {
    backgroundColor: Color.white,
  },

  height: props?.size === "small" ? "18px" : "32px",
  width: props?.size === "small" ? "18px" : "32px",
}));

export const CloseIcon = styling(XMarkIcon)({
  color: Color.grey,
  height: "24px",
  width: "24px",

  transition: "all 0.1s ease-in-out",

  "&:hover": {
    color: Color.darkGrey,
  },
});

export const CheckIcon = styling(CheckCircleIcon)({
  color: Color.buttonGreen,
  height: "32px",
  width: "32px",

  "@media (max-width: 640px)": {
    height: "24px",
    width: "24px",
  },
});

export const WarnIcon = styling(ExclamationCircleIcon)({
  color: Color.warn,
  height: "24px",
  width: "24px",

  "@media (max-width: 640px)": {
    height: "18px",
    width: "18px",
  },
});

export const CheckboxIcon = styling(Check)((props) => ({
  color: props?.hover
    ? props?.selected
      ? Color.white
      : Color.black
    : Color.white,
  width: props?.size === "small" ? "12px" : "18px",
  height: props?.size === "small" ? "12px" : "18px",

  transition: "all 0.1s ease-in-out",
}));
