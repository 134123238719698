import { useMediaQuery } from "@mui/material";
import React from "react";
import * as config from "../../../../config/config";
import { BoxContainer, LinkStyled, TextStyled } from "../styles";
import {
  AdArtContainer,
  AdContainer,
  AdSubtitle,
  AdTextContainer,
  AdTitle,
  ArtImage,
  DownloadButtonsContainer,
  DownloadChip,
  DownloadIcon,
  QrContainer,
  QrImage,
} from "./styles";

export default function MobileAppAd() {
  const isMobile = useMediaQuery("(max-width: 640px)");

  const storeList = [
    {
      name: "App Store",
      iconUrl: "/images/hiredly/apple-store-logo.webp",
      alt: "apple app store",
      downloadSlug: "https://itunes.apple.com/us/app/wobb/id981491669?mt=8",
    },
    {
      name: "Play Store",
      iconUrl: "/images/hiredly/play-store-logo.webp",
      alt: "google play store",
      downloadSlug:
        "https://play.google.com/store/apps/details?id=com.wobb.hunted&hl=en",
    },
    {
      name: "AppGallery",
      iconUrl: "/images/hiredly/appgallery-logo.webp",
      alt: "huawei app gallery",
      downloadSlug:
        "https://appgallery.huawei.com/#/app/C101027941?channelId=homepage-footer&id=8c375cc9194d469488f3f163394cfbd5&s=EDFF426DF95D1FCBCCE03918FB64DFF9C7197D34B481DE8A2BE8654FDF391EFB&detailType=0&v=",
    },
  ];

  const imageArtUrl = isMobile
    ? "/images/hiredly/apply-mobile-app-ad-mobile.webp"
    : "/images/hiredly/apply-mobile-app-ad-desktop.webp";

  return (
    <AdContainer>
      <AdTextContainer>
        <BoxContainer>
          <BoxContainer flexDirection={"column"} gap={"0.75rem"}>
            <AdTitle>
              If you're shortlisted, employers may message you directly on our
              app!
            </AdTitle>
            <AdSubtitle>
              Don't miss a message, download the Hiredly App here!
            </AdSubtitle>
          </BoxContainer>
          <QrContainer>
            <QrImage
              src={
                config.assetDomain +
                "/images/hiredly/mobile-app-qr-download.webp"
              }
            />
          </QrContainer>
        </BoxContainer>
        {isMobile && (
          <DownloadButtonsContainer>
            {storeList.map((store, index) => {
              return <MobileAppDownloadChip store={store} key={index} />;
            })}
          </DownloadButtonsContainer>
        )}
      </AdTextContainer>
      <AdArtContainer>
        <ArtImage src={config.assetDomain + imageArtUrl} />
      </AdArtContainer>
    </AdContainer>
  );
}

function MobileAppDownloadChip(props) {
  const { store } = props;
  return (
    <LinkStyled
      href={store.downloadSlug}
      rel="noopener"
      target="_blank"
      textDecoration={"none"}
    >
      <DownloadChip>
        <DownloadIcon
          src={config.assetDomain + store.iconUrl}
          alt={store.alt}
        />
        <BoxContainer flexDirection={"column"}>
          <TextStyled
            fontSize={"7.325px"}
            fontWeight={300}
            letterSpacing={"0.073px"}
          >
            Get app -
          </TextStyled>
          <TextStyled
            fontSize={"8.371px"}
            fontWeight={600}
            letterSpacing={"0.084px"}
          >
            {store.name}
          </TextStyled>
        </BoxContainer>
      </DownloadChip>
    </LinkStyled>
  );
}
