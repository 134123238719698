import Box from "@mui/material/Box";
import { styled as styling } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Color from "../../../../assets/colors";

export const AdContainer = styling("div")({
  position: "relative",
  display: "flex",
  justifyContent: "center",

  borderRadius: "0.5rem",

  height: "199px",
  width: "100%",
  overflow: "clip",

  "@media (max-width: 640px)": {
    flexDirection: "column-reverse",
    border: "1px solid" + Color.grey,
    minHeight: "354px",
  },
});

export const AdTextContainer = styling("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  gap: "1.125rem",

  background: "linear-gradient(100deg, #512ACC 0.18%, #7556DC 41.6%)",

  height: "100%",
  width: "60%",
  padding: "2rem 1.5rem",

  "@media (max-width: 640px)": {
    gap: "0.5rem",
    height: "58%",
    width: "100%",
    padding: "0.875rem 1.5rem 1rem",
  },
});

export const AdArtContainer = styling("div")({
  backgroundColor: "#EFEFFD",
  width: "40%",

  "@media (max-width: 640px)": {
    height: "42%",
    width: "100%",
  },
});

export const QrContainer = styling("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  borderRadius: "0.75rem",
  border: "1px solid" + Color.black,
  backgroundColor: Color.white,

  height: "112px",
  width: "112px",
  padding: "0.75rem",

  "@media (max-width: 640px)": {
    borderRadius: "0.5rem",
    height: "96px",
    width: "96px",
    padding: "0.5rem",
  },
});

export const DownloadButtonsContainer = styling(Box)((props) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "0.25rem",
}));

export const AdTitle = styling(Typography)({
  color: Color.white,
  fontFamily: "Manrope",
  fontSize: "1.25rem",
  fontWeight: 600,
  lineHeight: "24px",
  letterSpacing: "0.15px",
  textWrap: "wrap",

  "@media (max-width: 640px)": {
    fontSize: "0.875rem",
    lineHeight: "21px",
  },
});

export const AdSubtitle = styling(Typography)({
  color: Color.white,
  fontFamily: "Manrope",
  fontSize: "1rem",
  fontWeight: 500,
  lineHeight: "24px",
  letterSpacing: "0.15px",
  textWrap: "wrap",

  "@media (max-width: 640px)": {
    fontSize: "0.625rem",
    lineHeight: "15px",
  },
});

export const QrImage = styling("img")({
  height: "100px",
  width: "100px",

  "@media (max-width: 640px)": {
    height: "80px",
    width: "80px",
  },
});

export const ArtImage = styling("img")({
  position: "absolute",
  top: -22,
  right: -30,
  objectFit: "contain",

  height: "140%",
  width: "auto",

  "@media (max-width: 1279px)": {
    right: -60,
  },

  "@media (max-width: 640px)": {
    top: -28,
    right: -6,
    height: "60%",
  },
});

export const DownloadChip = styling("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",

  color: Color.black,
  backgroundColor: Color.white,
  border: "0.5px solid" + Color.black,
  borderRadius: "0.375rem",

  width: "5.25rem",
  padding: "0.26rem 0.4575rem",
  userSelect: "none",
});

export const DownloadIcon = styling("img")({
  backgroundColor: Color.white,
  height: "18px",
  width: "auto",
});
